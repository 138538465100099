export enum LocalStorageEnum {
  token = '_t',
  user_id = '_u',
  refresh_token = '_rft',
  permissions = '_perm',
  role = '_r',
  school_id = '_school',
  language = '_lang',
  mlvt_service = 'mlvt_svc'
}
